<template>
  <div :id="item.fields.id"></div>
  <FormKit
    v-if="item.fields.showSearchBar"
    :id="formId"
    name="search-form"
    type="form"
    :actions="false"
    form-class="col-12 col-md-10 mx-auto"
    @submit="
      () => {
        return false;
      }
    "
  >
    <FormKit
      id="search-box-value"
      v-model="queryString"
      type="search"
      name="search-value"
      autocomplete="off"
      outer-class="fk-search-bar"
      :floating-label="false"
      :placeholder="searchPlaceHolder"
      :label="searchPlaceHolder"
    >
      <template #suffix>
        <button type="submit" class="fk-search-bar__submit" tabindex="-1">
          <FormKitIcon icon="search" />
        </button>
      </template>
    </FormKit>
  </FormKit>
  <TagList
    v-if="item.fields.showTagList"
    :tags="topics"
    alignment="center"
    @click="topicsOnClick"
  />
  <span
    v-if="(item.fields.showTagList || item.fields.showSearchBar) && hasResults"
    v-html="searchPageInformation"
  />
  <TeaserCardsBlock
    v-if="hasResults"
    :items="teaserCards"
    grid-type="masonry"
    :header-gutter="false"
    :max-columns="3"
    link-icon-position="content"
  />
  <div v-else-if="noResultsRichText" class="text rte-text">
    <LibRichTextRenderer :rich-text="noResultsRichText" />
  </div>

  <div class="text-center">
    <Button
      v-if="ensureArray(teaserCards).length < searchTotalHits"
      @click="onLoadMore"
    >
      {{ loadMoreText }}
    </Button>
  </div>
  <div v-if="pagesError">
    {{ pagesError }}
  </div>
  <Spacer :spacing-grid="'xsmall'" :width="100" />
</template>

<script setup lang="ts">
import type { Ref } from 'vue';
import {
  Button,
  Spacer,
  TagList,
  TeaserCardsBlock
} from '@hypercodestudio/basler-components';
import { FormKitIcon } from '@formkit/vue';
import type { TagInterface } from '@hypercodestudio/basler-components/dist/components/elements/taglist/TagList.vue';
import type { Props as TeaserCardProps } from '@hypercodestudio/basler-components/dist/components/modules/TeaserCard.vue';
import type { ITagOverviewSection } from '~/lib/ContentfulService';
import { isDefined } from '~~/utils/guards/isDefined';
import { ensureArray } from '~~/utils/ensureArray';
import { debounce } from '~/composables/useDebounce';
import { transformAlgoliaContentfulPageToTeaserCard } from '~/utils/transformAlgoliaContentfulPageToTeaserCard';
import { useHTMLDictionary } from '~/composables/useHTMLDictionary';
import type {
  TagOverviewApiResponseModel,
  TagOverviewContentfulPage
} from '~/server/api/algolia/tag-overview.get';
import { isEntryResolveError } from '~/utils/guards/isEntryResolveError';
import { DETAILED_TAG } from '~/utils/shop/constants';

interface Props {
  item: ITagOverviewSection;
}

const props = defineProps<Props>();

const route = useRoute();
const router = useRouter();
const locale = useLocale();
const { $dictionary, $textDictionary } = useNuxtApp();
const formId = useId();
const htmlDictionary = useHTMLDictionary([
  'tagOverviewSection.searchResults.pageInformation'
]);

const queryString = ref<string>('');
const searchPageInformation = ref<string>('');
const searchOffset = ref<number>(0);

const QUERY_KEY_NAME_TOPICS = 'topic';

// TODO: RENAME INTERNAL FIELD tags TO topics
const editorialTopics =
  props.item.fields.tags
    ?.filter((entry) => !isEntryResolveError(entry))
    ?.map((t) => t.fields.slug) ?? [];
const filterContentfulTags = (props.item.fields.tagFilter ?? []) as string[];

const indexedPages = await useAlgoliaContentfulSearch({
  locale: locale.value,
  query: '',
  indexSuffix: props.item.fields.sortByDate ? '_sortByDate' : '',
  offset: 0,
  length: 9,
  editorialTopics,
  topics: ensureArray(route.query[QUERY_KEY_NAME_TOPICS]).filter(isDefined),
  tags: filterContentfulTags,
  hitsPerPage: 9
});

const pages = ref<TagOverviewContentfulPage[]>(indexedPages?.hits ?? []);
const searchTotalHits = ref<number>(indexedPages?.nbHits ?? 0);
const hasResults = computed(() => searchTotalHits.value > 0);

let facetValues: Record<string, number>;
if (props.item.fields.showTagList) {
  facetValues =
    (
      await useAlgoliaContentfulSearch({
        locale: locale.value,
        query: '',
        indexSuffix: props.item.fields.sortByDate ? '_sortByDate' : '',
        offset: 0,
        length: 9,
        editorialTopics,
        topics: [],
        tags: filterContentfulTags,
        hitsPerPage: 9
      })
    )?.facets?.detailed_topics ?? ({} as Record<string, number>);
}

const detailedTopics = computed(() =>
  !props.item.fields.showTagList
    ? []
    : unique([...Object.keys(facetValues ?? {})])?.map(
        (detailedTag: string) => {
          const tag = detailedTag.split(DETAILED_TAG);
          return {
            title: tag[0],
            slug: tag[1]
          };
        }
      )
);

const topics: Ref<TagInterface[]> = ref<TagInterface[]>(
  !props.item.fields.showTagList
    ? []
    : detailedTopics.value
        ?.filter((t) => !editorialTopics.includes(t.slug))
        ?.sort()
        ?.map((t) => ({
          text: t.title,
          element: 'button',
          id: t.slug,
          showCloseIcon: ensureArray(
            route.query[QUERY_KEY_NAME_TOPICS]
          ).includes(t.slug)
        }))
);
const allTopics = topics.value ?? [];

const topicFilter = ref<string[]>(
  (() => {
    const values: string[] =
      topics.value
        ?.filter((t) => isDefined(t.showCloseIcon) && t.showCloseIcon)
        ?.map((t) => t.id ?? '') ?? [];

    // TODO RENAME INTERNAL FIELD tags TO topics
    props.item.fields.tags
      ?.filter(isDefined)
      ?.filter((t) => t.fields.slug)
      .forEach((t) => values.push(t.fields.slug));

    return unique(values);
  })()
);

// TODO: error handling
const pagesError = ref<unknown | null>(null);

function isNewSearchRequest(): boolean {
  return searchOffset.value === 0;
}

const makeSearchRequest = async () => {
  if (queryString.value != null && queryString.value.length <= 2) {
    allTopics.forEach((t) => {
      t.showCloseIcon = topicFilter.value.includes(t.id as string);
    });
    topics.value = allTopics;
  }
  pagesError.value = null;
  try {
    const perPage = isNewSearchRequest() ? 9 : 3;
    const data = await $fetch<TagOverviewApiResponseModel>(
      '/api/algolia/tag-overview',
      {
        ...DEFAULT_FETCH_OPTIONS,
        query: {
          locale: locale.value,
          query: queryString.value,
          indexSuffix: props.item.fields.sortByDate ? '_sortByDate' : '',
          tags: filterContentfulTags,
          topics: topicFilter.value,
          editorialTopics,
          hitsPerPage: perPage,
          offset: searchOffset.value,
          length: perPage
        }
      }
    );

    if (isNewSearchRequest()) {
      pages.value = data.hits ?? [];
      if (topics.value.length === 0) {
        topics.value = allTopics;
      }
    } else {
      pages.value = pages.value.concat(data.hits ?? []);
    }
    searchTotalHits.value = data.nbHits ?? 0;
  } catch (e) {
    pagesError.value = e;
  }
};

watch(
  [queryString],
  debounce(async () => {
    searchOffset.value = 0;
    topics.value = [];
    pages.value = [];
    searchTotalHits.value = 0;
    await makeSearchRequest();
  }, 1000)
);

watch(topicFilter, async () => {
  router.push({ query: { [QUERY_KEY_NAME_TOPICS]: topicFilter.value } });
  searchOffset.value = 0;
  await makeSearchRequest();
});

const onLoadMore = async () => {
  if (isNewSearchRequest()) {
    searchOffset.value = 9;
  } else {
    searchOffset.value += 3;
    if (searchOffset.value > searchTotalHits.value) {
      searchOffset.value = searchTotalHits.value;
    }
  }

  await makeSearchRequest();
};

const topicsOnClick = (event: TagInterface | unknown) => {
  if (
    !(
      typeof event === 'object' &&
      isDefined(event) &&
      'text' in event &&
      typeof event.text === 'string' &&
      'id' in event &&
      typeof event.id === 'string'
    )
  ) {
    return;
  }
  const t = topics.value.find((tag) => tag.id === event.id);
  if (t) {
    t.showCloseIcon = !t.showCloseIcon;
  }
  topicFilter.value = (() =>
    unique(
      topics.value?.filter((t) => t.showCloseIcon)?.map((t) => t.id ?? '') ?? []
    ))();
};

const teaserCards = computed(
  (): TeaserCardProps[] =>
    pages.value?.map((page) =>
      transformAlgoliaContentfulPageToTeaserCard(
        page,
        locale.value,
        props.item.fields.showDate
      )
    ) ?? []
);

const noResultsRichText =
  $dictionary.value['tagOverviewSection.searchResults.noResults'];

searchPageInformation.value =
  htmlDictionary.value['tagOverviewSection.searchResults.pageInformation']
    ?.replace('{count}', teaserCards.value.length.toString() ?? 0)
    ?.replace('{total}', searchTotalHits.value.toString()) ?? '';
const loadMoreText =
  $textDictionary.value['tagOverviewSection.searchResults.loadmore'] ??
  'Load more';
const searchPlaceHolder =
  $textDictionary.value['tagOverviewSection.searchResults.searchPlaceholder'] ??
  'Search';

// XXX: why not use a computed for `searchPageInformation`?
watch([searchTotalHits, teaserCards], () => {
  searchPageInformation.value =
    htmlDictionary.value['tagOverviewSection.searchResults.pageInformation']
      ?.replace('{count}', teaserCards.value.length.toString() ?? 0)
      ?.replace('{total}', searchTotalHits.value.toString()) ?? '';
});
</script>
